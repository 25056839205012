<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="video-container md:col-8 md:offset-2">
        <video
          ref="video"
          class="video"
          :src="video.length ? video[0].link : null"
          controlsList="nodownload noremoteplayback"
        />
        <div v-if="isOverlayDisplayed" class="video-overlay" @click="playVideo">
          <Play v-if="!isVideoPlaying" class="play-btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Play from '../../assets/images/Play.svg';

export default {
  components: {
    Play,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      isVideoPlaying: false,
      isOverlayDisplayed: true,
    };
  },
  computed: {
    video() {
      return this.payload.video;
    },
  },
  mounted() {
  },
  methods: {
    playVideo(e) {
      if (!this.isVideoPlaying) {
        e.preventDefault();
        this.$refs.video.play();
        this.isOverlayDisplayed = false;
        this.isVideoPlaying = !this.isVideoPlaying;
        this.$refs.video.setAttribute('controls', true);
      } else {
        e.preventDefault();
        this.$refs.video.pause();
        this.isVideoPlaying = !this.isVideoPlaying;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.video-container {
  position: relative;
  &:hover {
    cursor: pointer;
    .play-btn {
      color: var(--color-navy);
    }
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.video {
  width: 100%;
  // &::-webkit-media-controls-panel {
  //   display: none;
  //   // opacity: 0;
  //   transition: opacity .35s ease-out;
  // }
}

.play-btn {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  transition: color .3s ease-out;
}
</style>
